.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

body, html, #root {
  height: 100%;
  margin: 0;
}
